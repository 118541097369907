/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

interface ModalProps {
  width: string;
  height: string;
  title: string;
  children: JSX.Element | JSX.Element[];
  onClose: () => void;
  show: boolean;
}
interface ModalBodyProps {
  width: string;
  height: string;
}

const Modal = ({
  show,
  onClose,
  children,
  title,
  width,
  height,
}: ModalProps): any => {
  const [isBrowser, setIsBrowser] = useState(false);
  useEffect(() => {
    setIsBrowser(true);
  }, []);

  const handleCloseClick = (e: {preventDefault: () => void}) => {
    e.preventDefault();
    onClose();
  };

  const modalContent = show ? (
    <StyledModalOverlay onClick={(e) => e.stopPropagation()}>
      <StyledModal width={width} height={height}>
        <StyledModalHeader>
          <svg
            style={{cursor: 'pointer'}}
            onClick={handleCloseClick}
            width="30"
            height="30"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9Z"
              fill="white"
            />
            <path
              d="M8.96371 0C7.19086 0 5.45782 0.525712 3.98374 1.51066C2.50967 2.4956 1.36077 3.89554 0.682326 5.53345C0.00388384 7.17135 -0.173628 8.97365 0.172239 10.7124C0.518106 12.4512 1.37182 14.0484 2.62541 15.302C3.87901 16.5556 5.47619 17.4093 7.21498 17.7552C8.95377 18.101 10.7561 17.9235 12.394 17.2451C14.0319 16.5667 15.4318 15.4178 16.4168 13.9437C17.4017 12.4696 17.9274 10.7366 17.9274 8.96371C17.9249 6.58716 16.9797 4.30866 15.2992 2.62818C13.6188 0.947704 11.3403 0.00250968 8.96371 0ZM12.2096 11.2339C12.2737 11.298 12.3245 11.3741 12.3592 11.4578C12.3938 11.5415 12.4117 11.6312 12.4117 11.7218C12.4117 11.8124 12.3938 11.9021 12.3592 11.9858C12.3245 12.0695 12.2737 12.1455 12.2096 12.2096C12.1455 12.2737 12.0695 12.3245 11.9858 12.3592C11.9021 12.3938 11.8124 12.4117 11.7218 12.4117C11.6312 12.4117 11.5415 12.3938 11.4578 12.3592C11.3741 12.3245 11.298 12.2737 11.2339 12.2096L8.96371 9.93851L6.69348 12.2096C6.62942 12.2737 6.55336 12.3245 6.46966 12.3592C6.38596 12.3938 6.29625 12.4117 6.20565 12.4117C6.11505 12.4117 6.02534 12.3938 5.94164 12.3592C5.85793 12.3245 5.78188 12.2737 5.71782 12.2096C5.65375 12.1455 5.60294 12.0695 5.56826 11.9858C5.53359 11.9021 5.51575 11.8124 5.51575 11.7218C5.51575 11.6312 5.53359 11.5415 5.56826 11.4578C5.60294 11.3741 5.65375 11.298 5.71782 11.2339L7.98891 8.96371L5.71782 6.69348C5.58843 6.5641 5.51575 6.38862 5.51575 6.20565C5.51575 6.02267 5.58843 5.84719 5.71782 5.71781C5.8472 5.58843 6.02268 5.51575 6.20565 5.51575C6.38862 5.51575 6.5641 5.58843 6.69348 5.71781L8.96371 7.98891L11.2339 5.71781C11.298 5.65375 11.3741 5.60293 11.4578 5.56826C11.5415 5.53359 11.6312 5.51575 11.7218 5.51575C11.8124 5.51575 11.9021 5.53359 11.9858 5.56826C12.0695 5.60293 12.1455 5.65375 12.2096 5.71781C12.2737 5.78188 12.3245 5.85793 12.3592 5.94163C12.3938 6.02533 12.4117 6.11505 12.4117 6.20565C12.4117 6.29624 12.3938 6.38596 12.3592 6.46966C12.3245 6.55336 12.2737 6.62941 12.2096 6.69348L9.93852 8.96371L12.2096 11.2339Z"
              fill="#2F3071"
            />
          </svg>
        </StyledModalHeader>
        {title && (
          <StyledModalTitle className="text-m28">{title}</StyledModalTitle>
        )}
        <StyledModalBody>{children}</StyledModalBody>
      </StyledModal>
    </StyledModalOverlay>
  ) : null;

  if (isBrowser) {
    return ReactDOM.createPortal(
      modalContent,
      document.getElementById('modal-root') as Element
    );
  } else {
    return null;
  }
};

const StyledModalBody = styled.div`
  padding-top: 10px;
`;

const StyledModalHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: 25px;
`;

const StyledModal = styled.div<ModalBodyProps>`
  background: white;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border-radius: 15px;
  padding: 15px;
  z-index: 10;
  overflow-y: auto;
  max-height: 90vh;
  ::-webkit-scrollbar {
    display: none;
  }
  filter: drop-shadow(0px 10px 20px rgba(206, 207, 242, 0.23));
  overflow-y: auto;
`;
const StyledModalTitle = styled.div`
  text-align: center;
  width: 80%;
  margin-inline: auto;
`;
const StyledModalOverlay = styled.div`
  z-index: 100000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(47, 48, 113, 0.8);
`;

export default Modal;
