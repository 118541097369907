import {ClubApi} from '@src/api/club-api';
import {ImagesApi} from '@src/api/images-api';
import {UserApi} from '@src/api/user-api';
import {FileObj} from '@src/models/file';
import {User} from '@src/models/user';
import {parseError} from '@src/utils/error';
import {useAuthStore} from '@src/stores/auth-store';
import {
  dataUrlToFile,
  getBase64Extension,
  getBase64SizeInKb,
} from '@src/utils/photoUtils';
import {toast} from '@src/utils/toast';
import {i18n} from 'next-i18next';

class ImagesService {
  //   public interests: Array<Interest> = [];
  public async getFile(id: string) {
    const res = await ImagesApi.getImage(id);
    return URL.createObjectURL(res);
  }

  public async getFileWithId(id: string) {
    const res = await ImagesApi.getImage(id);
    return {res: URL.createObjectURL(res), id: id};
  }
  public downloadPDFFile(blob: Blob, fileName: string) {
    try {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      document.body.append(link);
      link.click();
      link.remove();
      setTimeout(() => URL.revokeObjectURL(link.href), 7000);
    } catch (e) {
      toast.error(parseError(e).message);
    }
  }

  public async getAllUserImagesHighQuality(
    user: User,
    type: 'USER_OTHER'
  ): Promise<{res: string; id: string}[]> {
    const toFetchFiles = user.files?.filter(
      (it) => it.isCompress === false && it.type === type
    );
    if (toFetchFiles === undefined) {
      return [];
    }
    const promises = toFetchFiles.map((it) => this.getFileWithId(it.id));
    const response = await Promise.all(promises);
    return response;
  }

  /**
   *
   * @param image
   * @param fileObjs
   * @param t
   * @param isAvatar
   * @returns object if image was propley created, false if error was raised
   */
  public async addNewImageFileCreator(
    image: string,
    fileObjs: FileObj[],
    type = 'USER_OTHER'
  ) {
    if (!fileObjs) {
      return false;
    }

    if (!this.validateImageType(image)) {
      toast.error(i18n?.t('error:image:bad-image-format') as string);
      return false;
    }

    if (!(await this.validateImageSize(image))) {
      toast.error(i18n?.t('error:image:too-big-image') as string);
      return false;
    }

    const order = this.findEmptyOrder(fileObjs, type);
    const galeryImage = await dataUrlToFile(image, `galery-image-${order}`);
    return {order: order, file: galeryImage};
  }

  public async addNewImagesFilesCreator(
    photos: {photo: string; file: File}[],
    fileObjs: FileObj[],
    type = 'USER_OTHER'
  ): Promise<{order: number; file: File}[] | false> {
    if (!fileObjs) {
      return false;
    }

    const availableOrders = this.findEmptyOrders(fileObjs, type);

    if (availableOrders.length < photos.length) {
      return false;
    }

    const imagesArr: {order: number; file: File}[] = [];
    for (const photo of photos) {
      if (!this.validateImageType(photo.photo)) {
        toast.error(i18n?.t('error:image:bad-image-format') as string);
        continue;
      }

      if (!(await this.validateImageSize(photo.photo))) {
        toast.error(i18n?.t('error:image:too-big-image') as string);
        continue;
      }

      const order = this.findEmptyOrder(fileObjs, type);
      const galeryImage = await dataUrlToFile(
        photo.photo,
        `galery-image-${order}`
      );
      imagesArr.push({order: order, file: galeryImage});
    }

    return imagesArr;
  }

  /**
   *
   * @param image
   * @param fileObjs
   * @returns object if image was propley created, false if error was raised
   */
  public async addNewImageFileCreatorAvatar(
    image: string,
    fileObjs: FileObj[]
  ) {
    if (fileObjs === undefined) {
      return false;
    }

    if (!this.validateImageType(image)) {
      toast.error(i18n?.t('error:image:bad-image-format') as string);
      return false;
    }

    if (!(await this.validateImageSize(image))) {
      toast.error(i18n?.t('error:image:too-big-image') as string);
      return false;
    }

    const galeryImage = await dataUrlToFile(image, `avatar`);
    return {file: galeryImage};
  }

  private validateImageType(image: string): boolean {
    if (
      !['png', 'jpg', 'jpeg', 'webp', 'heic', 'heif'].includes(
        getBase64Extension(image) as string
      )
    ) {
      return false;
    }

    return true;
  }

  private async validateImageSize(image: string): Promise<boolean> {
    // Max 8 MB
    if ((await getBase64SizeInKb(image)) > 8000000) {
      return false;
    }
    return true;
  }

  public findEmptyOrder(fileObjs: FileObj[], type: string): number {
    const userImages = fileObjs.filter((it) => it.type === type);
    const usedOrders: number[] = [];
    for (const it of userImages) {
      usedOrders.push(it.order);
    }
    const allOrders = [1, 2, 3, 4];
    const avaibleOrders = allOrders.filter((num) => !usedOrders.includes(num));

    const res = avaibleOrders[0];
    if (avaibleOrders.length === 0 || res === undefined) {
      throw new Error('No empty order!');
    }
    return res;
  }

  public findEmptyOrders(fileObjs: FileObj[], types: string[], allOrders = [1, 2, 3, 4]): number[] {
    const userImages = fileObjs.filter((it) => types.includes(it.type));
    const usedOrders: number[] = [];
    for (const it of userImages) {
      usedOrders.push(it.order);
    }
    const availableOrders = allOrders.filter(
      (num) => !usedOrders.includes(num)
    );

    return availableOrders;
  }

  public async getUserFilesArray() {
    const user = await UserApi.getCurrentUser();
    if (user && user.files) {
      return user.files;
    } else {
      return [];
    }
  }

  public async getUserAvatar() {
    const token = useAuthStore.getState().token;
    if (token) {
      let avatar = '';
      const files = await this.getUserFilesArray();
      const avatarFile = files.find((file) => file.type === 'USER_AVATAR');
      if (avatarFile) {
        avatar = await this.getFile(avatarFile.id);
      }
      return avatar;
    }
  }
  public getUserAvatarIdFromArray(filesArray: FileObj[]) {
    const avatarFile = filesArray.find((file) => file.type === 'USER_AVATAR');
    return avatarFile?.id;
  }
  public async getUserAvatarId() {
    const files = await this.getUserFilesArray();
    const avatarFile = files.find((file) => file.type === 'USER_AVATAR');

    return avatarFile?.id;
  }

  public getCompressedAvatarFromArray(filesArray?: FileObj[]) {
    if (filesArray === undefined) {
      return undefined;
    }

    return filesArray.find(
      (it) => it.type === 'USER_AVATAR' && it.isCompress === true
    )?.id;
  }

  public async deleteAllUserPictures() {
    const user = await UserApi.getCurrentUser();

    if (user.files) {
      const filesToDelete = user.files
        .filter((file) => file.isCompress ?? file)
        .map(async (file) => ImagesApi.deleteUserPicture(file.id));
      await Promise.all(filesToDelete);
    }
  }
  public async deleteAllCompanyPictures(clubId: string) {
    const club = await ClubApi.getDetails(clubId);
    if (club.files) {
      const filesToDelete = club.files
        .filter((file: FileObj) => file.isCompress ?? file)
        .map(async (file: FileObj) =>
          ImagesApi.deleteCompanyImage(file.id, clubId)
        );
      await Promise.all(filesToDelete);
    }
  }
}

export const imagesService = new ImagesService();
